<template>
  <el-container class="body">
    <el-aside width="175px" style="margin-right: 10px;">
      <h3 class="title">服务中心</h3>
      <el-menu
          default-active="2">
        <el-menu-item v-for="menu in menus" :key="menu.url">
          <i :class="menu.icon" v-if="menu.icon"></i>
          <span class="label">{{menu.name}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    name: 'CenterLayout',
    data(){
      return {
        menus: [
          {url: '', name: '礼品商城', icon: 'el-icon-s-shop'},
          {url: '', name: '医疗服务', icon: 'el-icon-first-aid-kit'},
          {url: '', name: '我的预约订单', icon: 'el-icon-s-order'},
          {url: '', name: '我的咨询', icon: 'el-icon-s-comment'},
          {url: '', name: '病人信息管理', icon: 'el-icon-s-claim'},
          {url: '', name: '我的礼物', icon: 'el-icon-present'},
          {url: '', name: '我的账户', icon: 'el-icon-s-finance'},
          {url: '', name: '我的收藏', icon: 'el-icon-star-on'},
          {url: '', name: '对我的评价', icon: 'el-icon-s-opportunity'},
          {url: '', name: '我的评价', icon: 'el-icon-chat-line-square'},
          {url: '', name: '我的上传', icon: 'el-icon-chat-line-square'},
          {url: '', name: '爱心优惠券', icon: 'el-icon-s-ticket\n'},
          {url: '', name: '更改手机号码', icon: 'el-icon-phone'},
          {url: '', name: '修改密码', icon: 'el-icon-unlock'},
          {url: '', name: '注销账号', icon: 'el-icon-s-release'},
          {url: '', name: '求职', icon: 'el-icon-s-custom'},
          {url: '', name: '排名提前', icon: 'el-icon-s-marketing'},
          {url: '', name: '意见建议', icon: 'el-icon-s-order'},
          {url: '', name: '网站使用说明'},
          {url: '', name: '版权声明'},
          {url: '', name: '我的文章及经验观点'},
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  .container {
    width: 1200px;
    margin: auto;


    .body {
      margin-top: 34px;

      .el-aside {
        background: #ffffff;

        .title {
          height: 58px;
          background: #535353;
          color: #ffffff;
          padding: 0px;
          margin: 0px;
          text-align: center;
          line-height: 58px;
          font-size: 22px;
        }
      }

      .el-menu {
        .el-menu-item {
          height: 48px;
          line-height: 48px;
          border-bottom: 1px inset;

          /deep/ i:before {
            color: $green !important;
          }
        }

      }
    }
  }
</style>
